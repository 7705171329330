import { useCallback, useEffect, useState } from "react";
import Loader from "../controls/loader/Loader";
import {
  getUserAccount,
  getUserReports,
} from "../../services/userSettingsService";
import OneViewContainer from "../containers/oneViewContainer/OneViewContainer";
import VisualPage from "./VisualPage";
import {
  getToken,
  getUserInfo,
  invokeMstrLogin,
} from "../../services/mstrRestService";
import { currentEnvironment } from "../../environments/environments";
import { useNavigate } from "react-router-dom";
import { ErrorMessages, LoaderMessages } from "../../utility/Messages";
import useCustomSearchParams from "../../utility/useCustomSearchParam";
import { UserReport } from "../../model/userReportsModel";
import {
  LDContext,
  LDProvider,
  LDReactOptions,
} from "launchdarkly-react-client-sdk";
import { TokenContext } from "../../contexts";
import { UserAccount } from "../../model/userAccountModel";
import { useIdleTimer } from "react-idle-timer";
import { MSTR_IDLE_TIMEOUT } from "../../config/mstrConfig";
import getError, { ErrorModel } from "../../utility/errorHelper";
import {
  getBridgeUserModel,
  setUserMstrId,
} from "../../utility/sessionStorageHelper";
import { BridgeUserModel } from "../../model/bridgeUserModel";

function LandingPage() {
  const [userReportsLoaded, setUserReportsLoaded] = useState(false);
  const [userAccount, setUserAccount] = useState<UserAccount>();
  const [mstrToken, setMstrToken] = useState<string>();
  const [reportApiError, setReportApiError] = useState<boolean>(false);
  const [accountApiError, setAccountApiError] = useState<boolean>(false);
  const [mstrApiError, setMstrApiError] = useState<boolean>(false);
  const mstrInvoked = (useCustomSearchParams("mstr_invoked") ??
    false) as boolean;
  const [bridgeUserModel, setBridgeUserModel] = useState<BridgeUserModel>();

  const navigate = useNavigate();

  const navigateToError = useCallback(
    (error: ErrorModel) => {
      navigate("/error", {
        state: {
          error: error,
        },
      });
    },
    [navigate]
  );

  const getReports = useCallback(
    async (
      token: string,
      attempts: number
    ): Promise<UserReport[] | undefined> => {
      let reports: UserReport[] | undefined;
      while (attempts-- > 0) {
        reports = await getUserReports(token);
        if (reports) return reports;
      }
      return reports;
    },
    []
  );

  const onIdleTimeout = () => {
    navigateToError(getError(ErrorMessages.sessionError));
  };

  useIdleTimer({
    onIdle: onIdleTimeout,
    timeout: MSTR_IDLE_TIMEOUT - 5 * 60 * 1000,
    crossTab: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const model = getBridgeUserModel();
      if (model) {
        clearInterval(interval);
        setBridgeUserModel(model);
      }
    }, 100);
  }, []);

  useEffect(() => {
    setReportApiError(false);
    setAccountApiError(false);
    setAccountApiError(false);
  }, []);

  useEffect(() => {
    initReports();
    async function initReports() {
      if (mstrToken && bridgeUserModel?.accessToken && !userReportsLoaded) {
        const reports = await getReports(bridgeUserModel.accessToken, 3);
        if (reports === undefined) setReportApiError(true);
        else setUserReportsLoaded(true);
      }
    }
  }, [bridgeUserModel?.accessToken, getReports, mstrToken, userReportsLoaded]);

  const getAccount = useCallback(
    async (
      token: string,
      attempts: number
    ): Promise<UserAccount | undefined> => {
      let account: UserAccount | undefined;
      while (attempts-- > 0) {
        account = await getUserAccount(token);
        if (account) return account;
      }
      return account;
    },
    []
  );

  useEffect(() => {
    initAccounts();
    async function initAccounts() {
      if (mstrToken && bridgeUserModel?.accessToken && !userAccount) {
        const account = await getAccount(bridgeUserModel.accessToken, 3);
        if (account === undefined) setAccountApiError(true);
        else {
          setUserAccount(account);
        }
      }
    }
  }, [bridgeUserModel?.accessToken, getAccount, mstrToken, userAccount]);

  useEffect(() => {
    if (mstrInvoked) invokeGetToken();
    else invokeMstrLogin();

    async function invokeGetToken() {
      const token = await getToken();

      if (!token) {
        setMstrApiError(true);
        return;
      }
      setMstrToken(token);
      setMstrApiError(false);
    }
  }, [mstrInvoked]);

  useEffect(() => {
    if (mstrApiError) navigateToError(getError(ErrorMessages.mstrDown));
    if (accountApiError)
      navigateToError(getError(ErrorMessages.accountApiDown));
    if (reportApiError) navigateToError(getError(ErrorMessages.settingApiDown));
  }, [accountApiError, mstrApiError, navigateToError, reportApiError]);

  useEffect(() => {
    if (mstrToken) {
      getMstrUserInfo();
    }

    async function getMstrUserInfo() {
      const userId = await getUserInfo();
      if (userId) setUserMstrId(userId);
    }
  }, [mstrToken]);

  if (
    userReportsLoaded &&
    userAccount &&
    userAccount.sources.length > 0 &&
    mstrToken
  ) {
    const clientId =
      currentEnvironment().customConfiguration.launchDarklyClientId;
    const context: LDContext = {
      kind: "user",
      key: bridgeUserModel!.sub,
      "common-org-id": userAccount.sources
        .map((s) => s.commonOrgEntity?.id ?? "")
        .join(","),
    };
    const options: LDReactOptions = { useCamelCaseFlagKeys: false };

    return (
      <LDProvider
        clientSideID={clientId}
        context={context}
        reactOptions={options}
      >
        <OneViewContainer>
          <TokenContext.Provider value={mstrToken}>
            <VisualPage />
          </TokenContext.Provider>
        </OneViewContainer>
      </LDProvider>
    );
  }

  return <Loader msg={LoaderMessages.mstrSession} />;
}

export default LandingPage;
